@import url(~antd/dist/antd.min.css);
@import url(~react-phone-input-2/lib/style.css);
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
.color-white {
  color: #fff; }

.color-red {
  color: #ea0101; }

.color-black {
  color: #000; }

.color-pink {
  color: #EF4B6F; }

.color-blue {
  color: #191970; }

.color-gold {
  color: #F2D74A !important; }

.bg-pink {
  background-color: #EF4B6F; }

.bg-viking {
  background-color: #63D6D3; }

.bg-ronchi {
  background-color: #F2D74A; }

.bg-green {
  background-color: #26cb26; }

.border-color-white {
  border-color: #fff; }

.border-color-pink {
  border-color: #EF4B6F; }

.border-color-gold {
  border-color: #F2D74A !important; }

.border-color-viking {
  background-color: #63D6D3; }

.border-color-ronchi {
  background-color: #F2D74A; }

.border-color-green {
  background-color: #26cb26; }

* {
  font-weight: inherit;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

:root {
  --animate-delay: 3.5s; }

.hidden {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Netscape */
  -moz-opacity: 0;
  /* Safari 1.x */
  -khtml-opacity: 0;
  /* Good browsers */
  opacity: 0;
  visibility: hidden; }

[data-aos="rotate"] {
  transform: rotate(11deg) scale(1.5);
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Netscape */
  -moz-opacity: 0;
  /* Safari 1.x */
  -khtml-opacity: 0;
  /* Good browsers */
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="rotate"].aos-animate {
    transform: rotate(0) scale(1);
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=1);
    /* Netscape */
    -moz-opacity: 1;
    /* Safari 1.x */
    -khtml-opacity: 1;
    /* Good browsers */
    opacity: 1; }

a:hover {
  color: #fff; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

input,
textarea,
select,
button {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  input:required, input:invalid,
  textarea:required,
  textarea:invalid,
  select:required,
  select:invalid,
  button:required,
  button:invalid {
    box-shadow: none; }
  input[type="number"],
  textarea[type="number"],
  select[type="number"],
  button[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button,
  textarea[type="number"]::-webkit-inner-spin-button,
  textarea[type="number"]::-webkit-outer-spin-button,
  select[type="number"]::-webkit-inner-spin-button,
  select[type="number"]::-webkit-outer-spin-button,
  button[type="number"]::-webkit-inner-spin-button,
  button[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; }
  input::-ms-clear,
  textarea::-ms-clear,
  select::-ms-clear,
  button::-ms-clear {
    display: none;
    width: 0;
    height: 0; }
  input::-ms-reveal,
  textarea::-ms-reveal,
  select::-ms-reveal,
  button::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }

.noSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

html,
body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  background-color: #FCFCFC; }
  html.overflow-hidden,
  body.overflow-hidden {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none; }

.top-wrapper {
  max-width: 1300px;
  width: 96%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .top-wrapper .left {
    max-width: 560px; }
  .top-wrapper .right {
    width: 620px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .top-wrapper .right > div {
      width: 100%; }
      .top-wrapper .right > div.icon-star svg {
        display: block;
        height: auto;
        width: 120px; }
      .top-wrapper .right > div.icon-rocket {
        display: flex;
        justify-content: flex-end; }
        .top-wrapper .right > div.icon-rocket svg {
          display: block;
          height: auto;
          width: 300px; }
  .top-wrapper h1 {
    font-weight: 300;
    font-size: 48px;
    margin: 0 0 20px;
    letter-spacing: 0.02em; }
  .top-wrapper p {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.02em; }

.content-wrapper {
  max-width: 1400px;
  width: 96%;
  margin: 0 auto; }

.bold {
  font-weight: 600; }

.resend-box {
  color: #191970;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: center; }
  .resend-box span {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline; }

.competitionLine {
  width: 100% !important;
  margin-top: 8px !important; }

.competitionTitle {
  width: 70% !important; }

.competitionButton {
  width: 28% !important; }

.btn {
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.04em;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  /* IE 5-7 */
  filter: alpha(opacity=1);
  /* Netscape */
  -moz-opacity: 1;
  /* Safari 1.x */
  -khtml-opacity: 1;
  /* Good browsers */
  opacity: 1;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; }
  @media (hover: hover) {
    .btn:not(.disabled):hover {
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=0.8);
      /* Netscape */
      -moz-opacity: 0.8;
      /* Safari 1.x */
      -khtml-opacity: 0.8;
      /* Good browsers */
      opacity: 0.8; } }
  .btn-small {
    font-size: 14px;
    display: block;
    width: 120px;
    height: 36px;
    line-height: 36px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -ms-border-radius: 40px;
    -moz-border-radius: 40px; }
    .btn-small.whit-border {
      line-height: 34px; }
  .btn-medium {
    font-size: 16px;
    width: 160px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -ms-border-radius: 40px;
    -moz-border-radius: 40px; }
    .btn-medium.whit-border {
      line-height: 38px; }
  .btn-long {
    font-size: 16px;
    width: 280px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -ms-border-radius: 40px;
    -moz-border-radius: 40px; }
    .btn-long.whit-border {
      line-height: 38px; }
  .btn-big {
    font-size: 18px;
    display: block;
    width: 200px;
    height: 46px;
    line-height: 46px;
    border-radius: 46px;
    -webkit-border-radius: 46px;
    -ms-border-radius: 46px;
    -moz-border-radius: 46px; }
    .btn-big.whit-border {
      line-height: 44px; }
  .btn.whit-border {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid; }
  .btn.whit-icon {
    padding: 0 18px;
    width: fit-content; }
    .btn.whit-icon svg {
      margin: 0 5px -5px 0; }
  .btn.disabled {
    cursor: not-allowed;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=0.5);
    /* Netscape */
    -moz-opacity: 0.5;
    /* Safari 1.x */
    -khtml-opacity: 0.5;
    /* Good browsers */
    opacity: 0.5; }

@keyframes atom {
  from {
    transform: none; }
  to {
    transform: translateY(-10px); } }

@keyframes electron-circle1 {
  from {
    transform: rotateY(70deg) rotateZ(20deg); }
  to {
    transform: rotateY(70deg) rotateZ(380deg); } }

@keyframes electron1 {
  from {
    transform: rotateZ(-20deg) rotateY(-70deg); }
  to {
    transform: rotateZ(-380deg) rotateY(-70deg); } }

@keyframes electron-circle2 {
  from {
    transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg); }
  to {
    transform: rotateY(60deg) rotateX(60deg) rotateZ(330deg); } }

@keyframes electron2 {
  from {
    transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg); }
  to {
    transform: rotateZ(-330deg) rotateX(-60deg) rotateY(-60deg); } }

@keyframes electron-circle3 {
  from {
    transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg); }
  to {
    transform: rotateY(-60deg) rotateX(60deg) rotateZ(460deg); } }

@keyframes electron3 {
  from {
    transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg); }
  to {
    transform: rotateZ(-460deg) rotateX(-60deg) rotateY(60deg); } }

.loader-box {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999; }
  .loader-box:after {
    background-color: #fff;
    content: '';
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%; }
  .loader-box.transparent:after {
    background-color: rgba(255, 255, 255, 0.7); }

.atom {
  z-index: 1;
  width: 120px;
  height: 120px;
  position: relative;
  animation: atom 1s ease-in-out infinite alternate;
  perspective: 300px;
  transform-style: preserve-3d; }
  .atom:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #171058; }
  .atom .electron {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 2px solid #191970;
    transform-style: preserve-3d; }
    .atom .electron:before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      margin: auto;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: #191970;
      transform-origin: 50% 50% 0; }
    .atom .electron:nth-child(1) {
      transform: rotateY(70deg) rotateZ(20deg);
      animation: electron-circle1 3s linear infinite; }
      .atom .electron:nth-child(1):before {
        transform: rotateZ(-20deg) rotateY(-70deg);
        animation: electron1 3s linear infinite; }
    .atom .electron:nth-child(2) {
      transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg);
      animation: electron-circle2 3s linear infinite; }
      .atom .electron:nth-child(2):before {
        transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg);
        animation: electron2 3s linear infinite; }
    .atom .electron:nth-child(3) {
      transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg);
      animation: electron-circle3 3s linear infinite; }
      .atom .electron:nth-child(3):before {
        transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg);
        animation: electron3 3s linear infinite; }

.react-tel-input .form-control {
  width: 100%; }

.react-tel-input:not(.disabled):hover .form-control,
.react-tel-input:not(.disabled):hover .flag-dropdown {
  border-color: #8383ad; }

.react-tel-input:not(.disabled):focus .form-control,
.react-tel-input:not(.disabled):focus .flag-dropdown {
  border-color: #8383ad;
  box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1);
  -moz-box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1); }

.react-tel-input:not(.disabled)-focused .form-control,
.react-tel-input:not(.disabled)-focused .flag-dropdown {
  border-color: #8383ad;
  box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1);
  -moz-box-shadow: 0 0 0 2px rgba(131, 131, 173, 0.1); }

.register-link {
  font-size: 15px;
  color: #191970; }
  .register-link a {
    color: #191970;
    text-decoration: underline;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=1);
    /* Netscape */
    -moz-opacity: 1;
    /* Safari 1.x */
    -khtml-opacity: 1;
    /* Good browsers */
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out; }
    @media (hover: hover) {
      .register-link a:hover {
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=0.8);
        /* Netscape */
        -moz-opacity: 0.8;
        /* Safari 1.x */
        -khtml-opacity: 0.8;
        /* Good browsers */
        opacity: 0.8; } }

@media screen and (max-width: 1680px) {
  .top-wrapper {
    max-width: 1100px;
    width: calc(100% - 200px); }
    .top-wrapper .left {
      max-width: 560px; }
    .top-wrapper .right {
      width: 520px; }
      .top-wrapper .right > div.icon-star svg {
        width: 100px; }
      .top-wrapper .right > div.icon-rocket svg {
        width: 260px; }
    .top-wrapper h1 {
      font-size: 40px; } }

@media screen and (max-width: 1280px) {
  .top-wrapper {
    max-width: 1100px;
    width: calc(100% - 300px); }
    .top-wrapper .left {
      max-width: 500px; }
    .top-wrapper .right {
      width: 480px; }
      .top-wrapper .right > div.icon-star svg {
        width: 90px; }
      .top-wrapper .right > div.icon-rocket svg {
        width: 240px; }
    .top-wrapper h1 {
      font-size: 38px; } }

@media screen and (max-width: 1024px) {
  .content-wrapper {
    width: 90%; }
  .top-wrapper {
    width: 90%;
    flex-wrap: wrap; }
    .top-wrapper .left {
      max-width: 100%;
      width: 100%;
      order: 2; }
    .top-wrapper .right {
      max-width: 100%;
      width: 80%;
      margin: 0 auto 30px; }
      .top-wrapper .right > div.icon-star svg {
        width: 90px; }
      .top-wrapper .right > div.icon-rocket svg {
        width: 240px; }
    .top-wrapper h1 {
      text-align: center;
      font-size: 38px; } }

@media screen and (max-width: 768px) {
  .top-wrapper .right > div.icon-star svg {
    width: 70px; }
  .top-wrapper .right > div.icon-rocket svg {
    width: 200px; }
  .top-wrapper h1 {
    font-size: 30px; } }

@media screen and (max-width: 720px) {
  .top-wrapper .btn {
    font-size: 16px;
    width: 160px;
    height: 40px;
    line-height: 36px;
    margin: 0 auto; } }

@media screen and (max-width: 520px) {
  .top-wrapper .right {
    width: 100%; }
    .top-wrapper .right > div.icon-star svg {
      width: 50px; }
    .top-wrapper .right > div.icon-rocket svg {
      width: 150px; } }

.ant-radio-input:focus + .ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner {
  border-color: #EF4B6F; }

.ant-input {
  height: 38px;
  font-size: 14px;
  letter-spacing: 0.02em;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -ms-border-radius: 6px;
  -moz-border-radius: 6px;
  transition: none 0.3s ease-in-out;
  -webkit-transition: none 0.3s ease-in-out;
  -moz-transition: none 0.3s ease-in-out; }
  .ant-input[disabled] {
    opacity: .4; }
  .ant-input-affix-wrapper {
    font-size: 14px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px; }
    .ant-input-affix-wrapper:focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-affix-wrapper:focus input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-affix-wrapper:hover {
      border-color: #171058; }
    .ant-input-affix-wrapper-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-affix-wrapper-focused input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-affix-wrapper input {
      height: auto; }
  .ant-input-group-wrapper {
    font-size: 14px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px; }
    .ant-input-group-wrapper:focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-group-wrapper:focus input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-group-wrapper:hover {
      border-color: #171058; }
    .ant-input-group-wrapper-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-group-wrapper-focused input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-group-wrapper input {
      height: 100%; }
  .ant-input-group {
    height: 38px; }
  .ant-input:not([disabled]):hover {
    border-color: #8383ad; }
  .ant-input:not([disabled]):focus {
    border-color: #8383ad;
    box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important;
    -webkit-box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important;
    -moz-box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important; }
  .ant-input:not([disabled])-focused {
    border-color: #8383ad;
    box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important;
    -webkit-box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important;
    -moz-box-shadow: inset 0 0 0 2px rgba(131, 131, 173, 0.1) !important; }
  .ant-input-search-button {
    height: 38px;
    width: 38px; }
    .ant-input-search-button svg {
      width: 14px;
      height: 14px; }
  .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #171058; }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:focus {
      border-color: #171058; }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:hover {
      border-color: #171058; }
  .ant-input-number {
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px; }
    .ant-input-number-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number-focused input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled):focus {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number:not(.ant-input-number.ant-input-number-disabled):focus input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled):hover {
      border-color: #171058; }
    .ant-input-number:not(.ant-input-number.ant-input-number-disabled)-focused {
      border-color: #171058;
      box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -webkit-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1);
      -moz-box-shadow: 0 0 0 1px rgba(23, 16, 88, 0.1); }
      .ant-input-number:not(.ant-input-number.ant-input-number-disabled)-focused input {
        border-color: initial !important;
        box-shadow: initial !important; }
    .ant-input-number-input {
      height: 36px;
      font-size: 14px; }
    .ant-input-number-disabled {
      opacity: .4; }

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #171058; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(25, 25, 112, 0.2); }

.ant-select-item-option-content span {
  font-weight: 600; }

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #171058;
  box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important;
  -webkit-box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important;
  -moz-box-shadow: inset 0 0 0 2px rgba(23, 16, 88, 0.1) !important; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selection-item, .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-placeholder {
  line-height: 38px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
  font-size: 14px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -ms-border-radius: 6px;
  -moz-border-radius: 6px; }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%; }

.ant-select-selection-item span {
  font-weight: 600; }

.ant-form-item {
  display: block;
  margin: 0 0 30px; }
  .ant-form-item-label {
    text-align: left;
    display: block; }
    .ant-form-item-label > label {
      height: auto;
      color: #191970;
      font-weight: 700;
      font-size: 10px;
      letter-spacing: 0.5em;
      text-transform: uppercase;
      margin: 0 0 5px 2px; }
      .ant-form-item-label > label:after, .ant-form-item-label > label:before {
        display: none; }
  .ant-form-item-required:after, .ant-form-item-required:before {
    display: none !important; }
  .ant-form-item-explain {
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: -28px; }

.ant-form .response-box {
  color: #ea0101;
  margin: 0 0 18px;
  font-weight: 400;
  font-size: 14px; }
  .ant-form .response-box.success {
    color: #191970; }

.ant-radio-group {
  line-height: 22px; }

.ant-radio-wrapper {
  font-weight: 600;
  font-size: 15px;
  color: #8E8EA0; }
  .ant-radio-wrapper-checked {
    color: #EF4B6F; }

.ant-radio-inner {
  border: 1px solid #8E8EA0; }

.ant-radio-checked .ant-radio-inner {
  border-color: #EF4B6F; }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #EF4B6F; }

.ant-checkbox-group {
  line-height: 22px; }

.ant-checkbox-wrapper {
  font-weight: 600;
  font-size: 15px;
  color: #8E8EA0; }
  .ant-checkbox-wrapper-checked {
    color: #EF4B6F; }

.ant-checkbox-inner {
  border: 1px solid #8E8EA0; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #EF4B6F;
  border-color: #EF4B6F; }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    background-color: #EF4B6F; }

.ant-modal {
  width: 680px; }
  .ant-modal-body {
    padding: 0; }
  .ant-modal-footer {
    border: 0; }
    .ant-modal-footer .button-wrap {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding-top: 40px; }
  .ant-modal-title {
    font-weight: 400;
    font-size: 24px;
    color: #191970;
    text-align: center;
    margin: 0 0 30px; }
  .ant-modal-text {
    color: #191970;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 30px;
    letter-spacing: 0.01em; }
  .ant-modal-content {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    -moz-border-radius: 20px;
    padding: 40px; }
    .ant-modal-content .ant-row:last-child {
      margin: 0; }
    .ant-modal-content button:not(.ant-modal-close) {
      margin: 30px auto 0; }
  .ant-modal .stripe-form .title {
    text-align: center; }
  .ant-modal .stripe-form .text {
    height: auto;
    color: #191970;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    margin: 0 0 2px 2px; }
  .ant-modal .stripe-form input,
  .ant-modal .stripe-form .StripeElement {
    display: block;
    margin: 0 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    outline: 0;
    border-radius: 4px;
    background: white;
    height: 38px;
    font-size: 14px;
    border: 1px solid #8383ad;
    letter-spacing: 0.02em;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px;
    transition: none 0.3s ease-in-out;
    -webkit-transition: none 0.3s ease-in-out;
    -moz-transition: none 0.3s ease-in-out; }
  .ant-modal .stripe-form input::placeholder {
    color: #aab7c4; }
  .ant-modal.confirm-modal .ant-modal-content {
    padding: 40px; }
  .ant-modal.confirm-modal .ant-modal-title {
    margin: 0 0 50px; }
  .ant-modal.stripe-modal .stripe-logo {
    height: auto;
    width: 100px;
    display: block;
    position: absolute;
    right: 18px;
    bottom: 15px; }
  .ant-modal.stripe-modal .ant-modal-content {
    padding: 40px 40px 80px; }
  .ant-modal.stripe-modal .ant-modal-title {
    margin: 0 0 50px; }
  .ant-modal-login-register {
    max-width: 580px;
    width: 96% !important;
    box-sizing: border-box; }
    .ant-modal-login-register .wrap-box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      text-align: center; }
    .ant-modal-login-register a {
      display: block;
      font-size: 16px;
      font-weight: 700;
      color: #191970;
      padding: 0 0 10px; }
      .ant-modal-login-register a .icon {
        margin: 0 0 20px; }
      .ant-modal-login-register a img {
        display: block;
        height: auto;
        width: 100%;
        max-width: 230px;
        margin: 0 auto; }
  .ant-modal-results {
    max-width: 580px;
    width: 96% !important;
    box-sizing: border-box; }
    .ant-modal-results .wrap-box-results {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      text-align: center;
      flex-direction: column; }
    .ant-modal-results a {
      display: block;
      font-size: 16px;
      font-weight: 700;
      color: #191970;
      padding: 0 0 10px;
      text-decoration: underline; }
      .ant-modal-results a .icon {
        margin: 0 0 20px; }
      .ant-modal-results a img {
        display: block;
        height: auto;
        width: 100%;
        max-width: 230px;
        margin: 0 auto; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

header {
  padding: 24px 0;
  width: 100%; }
  header-wrapper {
    max-width: calc(100% - 72px);
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  header-logo svg {
    display: block;
    width: 220px;
    height: auto; }
  header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    header-menu-item:not(:last-child) {
      margin: 0 40px 0 0; }
    header-menu-item:not(.button-wrap) a {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      letter-spacing: 0.04em;
      /* IE 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
      /* IE 5-7 */
      filter: alpha(opacity=1);
      /* Netscape */
      -moz-opacity: 1;
      /* Safari 1.x */
      -khtml-opacity: 1;
      /* Good browsers */
      opacity: 1;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease; }
      @media (hover: hover) {
        header-menu-item:not(.button-wrap) a:hover {
          /* IE 8 */
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
          /* IE 5-7 */
          filter: alpha(opacity=0.8);
          /* Netscape */
          -moz-opacity: 0.8;
          /* Safari 1.x */
          -khtml-opacity: 0.8;
          /* Good browsers */
          opacity: 0.8; } }
      header-menu-item:not(.button-wrap) a.whit-icon {
        align-items: center;
        display: flex; }
        header-menu-item:not(.button-wrap) a.whit-icon svg {
          margin: 0 10px 0 0; }
    header-menu-item.button-wrap {
      width: fit-content;
      display: flex;
      align-items: center; }
      header-menu-item.button-wrap a, header-menu-item.button-wrap div {
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=1);
        /* Netscape */
        -moz-opacity: 1;
        /* Safari 1.x */
        -khtml-opacity: 1;
        /* Good browsers */
        opacity: 1;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease; }
        header-menu-item.button-wrap a:first-child, header-menu-item.button-wrap div:first-child {
          margin: 0 18px 0 0; }
        @media (hover: hover) {
          header-menu-item.button-wrap a:hover, header-menu-item.button-wrap div:hover {
            /* IE 8 */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
            /* IE 5-7 */
            filter: alpha(opacity=0.8);
            /* Netscape */
            -moz-opacity: 0.8;
            /* Safari 1.x */
            -khtml-opacity: 0.8;
            /* Good browsers */
            opacity: 0.8; } }
    header-menu-button {
      display: none;
      cursor: pointer;
      z-index: 1; }
      header-menu-button i {
        display: block;
        cursor: pointer;
        width: 20px;
        height: 16px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out; }
        header-menu-button i span {
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
          background: #fff;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out; }
          header-menu-button i span:nth-child(1) {
            top: 0; }
          header-menu-button i span:nth-child(2) {
            top: 6px; }
          header-menu-button i span:nth-child(3) {
            top: 6px; }
          header-menu-button i span:nth-child(4) {
            top: 12px; }
      header-menu-button.clicked i span:nth-child(1) {
        width: 0;
        left: 50%;
        opacity: 0; }
      header-menu-button.clicked i span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg); }
      header-menu-button.clicked i span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg); }
      header-menu-button.clicked i span:nth-child(4) {
        width: 0;
        left: 50%;
        opacity: 0; }
  header .user-tooltip {
    position: relative; }
    header .user-tooltip-box {
      min-width: 140px;
      right: 0;
      width: 100%;
      position: absolute;
      top: 45px;
      background-color: #fff;
      box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
      -webkit-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
      -moz-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -ms-border-radius: 10px;
      -moz-border-radius: 10px; }
      header .user-tooltip-box a, header .user-tooltip-box div {
        cursor: pointer;
        padding: 10px 25px;
        display: block;
        font-size: 14px;
        font-weight: 600;
        color: #191970;
        letter-spacing: 0.04em;
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=1);
        /* Netscape */
        -moz-opacity: 1;
        /* Safari 1.x */
        -khtml-opacity: 1;
        /* Good browsers */
        opacity: 1;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease; }
        @media (hover: hover) {
          header .user-tooltip-box a:hover, header .user-tooltip-box div:hover {
            /* IE 8 */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
            /* IE 5-7 */
            filter: alpha(opacity=0.8);
            /* Netscape */
            -moz-opacity: 0.8;
            /* Safari 1.x */
            -khtml-opacity: 0.8;
            /* Good browsers */
            opacity: 0.8; } }
      header .user-tooltip-box a {
        border-bottom: 1px solid #E9E9EF; }
      header .user-tooltip-box div {
        color: #EF4B6F; }
  header.menu-open header-logo {
    position: relative;
    z-index: 9; }
  header.menu-open header-menu {
    z-index: 1;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    position: fixed;
    height: 100vh;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center; }
    header.menu-open header-menu-item {
      width: 100%; }
      header.menu-open header-menu-item:not(:last-child) {
        margin: 0 auto 25px; }
      header.menu-open header-menu-item:not(.button-wrap) a {
        font-size: 14px; }
      header.menu-open header-menu-item.button-wrap {
        display: block;
        margin: 20px 0 0; }
        header.menu-open header-menu-item.button-wrap a, header.menu-open header-menu-item.button-wrap div {
          width: 120px;
          line-height: 34px;
          height: 36px;
          margin: 0 auto;
          font-size: 14px; }
          header.menu-open header-menu-item.button-wrap a:not(:last-child), header.menu-open header-menu-item.button-wrap div:not(:last-child) {
            margin: 0 auto 20px; }
  header.menu-open .user-tooltip-btn {
    margin: 0 auto; }
  header.menu-open .user-tooltip-box {
    max-width: 220px;
    left: 0;
    margin: auto; }

@media screen and (max-width: 1280px) {
  header-logo svg {
    width: 180px; }
  header-menu-item:not(:last-child) {
    margin: 0 24px 0 0; }
  header-menu-item:not(.button-wrap) a {
    font-size: 13px; }
  header-menu-item.button-wrap a, header-menu-item.button-wrap div {
    line-height: 32px !important;
    height: 34px;
    font-size: 13px;
    width: 100px; } }

@media screen and (max-width: 1040px) {
  header-menu {
    display: none; }
    header-menu-button {
      display: block; }
    header-menu-item:not(.button-wrap) a.btn {
      margin: 0 auto; } }

footer {
  background-color: #171058;
  padding: 80px 0 50px;
  position: relative; }
  footer .pattern {
    position: absolute;
    width: 100%;
    height: 60px;
    top: -1px;
    background-image: url("../svg/FooterMask.svg");
    background-size: 120%;
    background-position: center top;
    background-repeat: no-repeat; }
  footer-wrapper {
    display: flex;
    max-width: 1490px;
    width: 96%;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.02em; }
  footer .item.col-1 {
    width: 460px; }
    footer .item.col-1 .logo {
      margin: 0 0 80px; }
      footer .item.col-1 .logo svg {
        display: block;
        height: auto;
        width: 190px; }
    footer .item.col-1 .description {
      margin: 20px 0 58px;
      line-height: 1.6;
      font-size: 14px; }
  footer .item.col-3 {
    width: 280px; }
    footer .item.col-3 .text {
      line-height: 25px; }
  footer .copy-right-mobile {
    display: none; }
  footer .footer-menu li:not(:last-child) {
    margin-bottom: 13px; }
  footer .footer-menu li a {
    font-weight: 400;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    /* IE 5-7 */
    filter: alpha(opacity=1);
    /* Netscape */
    -moz-opacity: 1;
    /* Safari 1.x */
    -khtml-opacity: 1;
    /* Good browsers */
    opacity: 1;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease; }
    @media (hover: hover) {
      footer .footer-menu li a:hover {
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=0.8);
        /* Netscape */
        -moz-opacity: 0.8;
        /* Safari 1.x */
        -khtml-opacity: 0.8;
        /* Good browsers */
        opacity: 0.8; } }
    footer .footer-menu li a.bold {
      font-weight: 600; }
  footer .social {
    margin: 9px 0 0; }
    footer .social .social-icon-box {
      margin: 10px 0 0;
      display: flex;
      align-items: center; }
      footer .social .social-icon-box .social-item:not(:last-child) {
        margin-right: 20px; }
      footer .social .social-icon-box svg {
        display: block;
        width: 30px;
        height: auto;
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
        /* IE 5-7 */
        filter: alpha(opacity=1);
        /* Netscape */
        -moz-opacity: 1;
        /* Safari 1.x */
        -khtml-opacity: 1;
        /* Good browsers */
        opacity: 1;
        transition: all 0.1s ease;
        -webkit-transition: all 0.1s ease;
        -moz-transition: all 0.1s ease; }
      @media (hover: hover) {
        footer .social .social-icon-box a:hover svg {
          /* IE 8 */
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
          /* IE 5-7 */
          filter: alpha(opacity=0.8);
          /* Netscape */
          -moz-opacity: 0.8;
          /* Safari 1.x */
          -khtml-opacity: 0.8;
          /* Good browsers */
          opacity: 0.8; } }

@media screen and (max-width: 1040px) {
  footer {
    padding: 60px 0 40px; }
    footer-wrapper {
      display: block;
      text-align: center; }
    footer .item {
      width: 100% !important; }
      footer .item.col-1 .logo {
        margin: 0 0 30px; }
        footer .item.col-1 .logo svg {
          max-width: 170px; }
        footer .item.col-1 .logo a {
          display: inline-block; }
      footer .item.col-2 {
        margin: 0 0 30px; }
    footer .social-icon-box {
      justify-content: center; }
    footer .copy-right {
      display: none; }
      footer .copy-right-mobile {
        margin: 30px 0 0;
        display: block; } }

.page-login header {
  position: absolute;
  z-index: 9; }

.page-login section.top {
  padding: 100px 0 140px;
  background-color: #171058;
  position: relative;
  background-image: url("../svg/HeaderBigMask.svg"), url("../images/HeaderBG.jpg");
  background-size: 120%, cover;
  background-position: center 101%, center;
  background-repeat: no-repeat, no-repeat; }

.page-login section.content {
  padding: 50px 0 180px; }
  .page-login section.content .content-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1300px; }
  .page-login section.content .left {
    max-width: 470px; }
    .page-login section.content .left svg {
      display: block;
      height: auto;
      max-width: 230px;
      width: 100%; }
    .page-login section.content .left h2 {
      font-size: 28px;
      font-weight: 400;
      color: #191970;
      margin: 20px 0 5px;
      letter-spacing: 0.01em; }
    .page-login section.content .left .text {
      letter-spacing: 0.02em;
      color: #191970;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400; }
  .page-login section.content .form {
    max-width: 640px;
    width: 100%; }
    .page-login section.content .form .forgot-link {
      position: absolute;
      right: 0;
      top: -24px;
      text-decoration: underline;
      font-weight: 400;
      font-size: 12px;
      color: #191970;
      letter-spacing: 0.02em; }
    .page-login section.content .form button {
      margin: 20px 0 0; }

@media screen and (max-width: 1680px) {
  .page-login section.content .content-wrapper {
    max-width: 1100px; }
  .page-login section.content .left {
    max-width: 380px;
    margin: 0 30px 0 0; }
  .page-login section.content .form {
    max-width: 540px; } }

@media screen and (max-width: 1024px) {
  .page-login section.top {
    padding: 120px 0 100px; }
  .page-login section.content .content-wrapper {
    display: block;
    max-width: 600px; }
  .page-login section.content .left {
    max-width: 100%;
    margin: 0; }
  .page-login section.content .form {
    margin: 40px 0 0;
    max-width: 100%; } }

.page-register header {
  position: absolute;
  z-index: 9; }

.page-register section.top {
  padding: 100px 0 140px;
  background-color: #171058;
  position: relative;
  background-image: url("../svg/HeaderBigMask.svg"), url("../images/HeaderBG.jpg");
  background-size: 120%, cover;
  background-position: center 101%, center;
  background-repeat: no-repeat, no-repeat; }

.page-register section.content {
  padding: 50px 0; }
  .page-register section.content .content-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1300px; }
    .page-register section.content .content-wrapper.middle {
      align-items: center; }
  .page-register section.content .left {
    max-width: 470px; }
    .page-register section.content .left svg {
      display: block;
      height: auto;
      max-width: 230px;
      width: 100%; }
    .page-register section.content .left h2 {
      font-size: 28px;
      font-weight: 400;
      color: #191970;
      margin: 20px 0 5px;
      letter-spacing: 0.01em; }
    .page-register section.content .left .text {
      letter-spacing: 0.02em;
      color: #191970;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400; }
  .page-register section.content .form {
    max-width: 640px;
    width: 100%; }
    .page-register section.content .form.mt {
      margin: 170px 0 0; }
      .page-register section.content .form.mt h2 {
        margin: 0 0 40px;
        font-size: 20px; }
    .page-register section.content .form .ant-row .ant-row {
      margin: 0; }
    .page-register section.content .form h2 {
      font-size: 24px;
      font-weight: 400;
      color: #191970;
      letter-spacing: 0.01em;
      text-align: center; }
    .page-register section.content .form .description {
      color: #191970;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.5;
      margin: 5px 0 60px;
      letter-spacing: 0.025em;
      text-align: center; }
    .page-register section.content .form .center-info {
      font-size: 13px;
      color: #7F7F80;
      letter-spacing: 0.02em; }
    .page-register section.content .form .text-info {
      position: absolute;
      right: 0;
      top: 0;
      font-weight: 400;
      font-size: 12px;
      color: #191970;
      letter-spacing: 0.02em; }
    .page-register section.content .form button {
      margin: 20px 0 0; }

@media screen and (max-width: 1680px) {
  .page-register section.content .content-wrapper {
    max-width: 1100px; }
  .page-register section.content .left {
    max-width: 380px;
    margin: 0 30px 0 0; }
  .page-register section.content .form {
    max-width: 540px; } }

@media screen and (max-width: 1024px) {
  .page-register section.top {
    padding: 120px 0 100px; }
  .page-register section.content .content-wrapper {
    display: block;
    max-width: 600px; }
  .page-register section.content .left {
    max-width: 100%;
    margin: 0; }
  .page-register section.content .form {
    margin: 40px 0 0;
    max-width: 100%; }
    .page-register section.content .form.mt {
      margin: 40px 0 0; } }

@media screen and (max-width: 768px) {
  .page-register section.content .form .text-info {
    right: 0;
    top: 5px;
    font-size: 9px; } }

.page-forgot header {
  background-color: #171058;
  background-image: url("../svg/HeaderSmallMask.svg");
  background-size: 120%;
  background-position: center 101%;
  background-repeat: no-repeat;
  padding: 24px 0 70px; }

.page-forgot .content-wrapper {
  max-width: 700px;
  margin: 0 auto;
  width: 96%;
  text-align: center; }

.page-forgot .page-container {
  min-height: calc(100vh - 363px);
  display: flex;
  align-items: center; }
  .page-forgot .page-container h1 {
    font-size: 28px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em; }
  .page-forgot .page-container h2 {
    font-size: 24px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em; }
  .page-forgot .page-container .description {
    color: #191970;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;
    margin: 5px 0 60px;
    letter-spacing: 0.025em; }
  .page-forgot .page-container .form .ant-form-item {
    margin: 0; }
    .page-forgot .page-container .form .ant-form-item:not(:last-child) {
      margin: 0 0 50px; }
    .page-forgot .page-container .form .ant-form-item button {
      margin: 0 auto; }

.page-reset header {
  background-color: #171058;
  background-image: url("../svg/HeaderSmallMask.svg");
  background-size: 120%;
  background-position: center 101%;
  background-repeat: no-repeat;
  padding: 24px 0 70px; }

.page-reset .content-wrapper {
  max-width: 700px;
  margin: 0 auto;
  width: 96%;
  text-align: center; }

.page-reset .page-container {
  min-height: calc(100vh - 363px);
  display: flex;
  align-items: center; }
  .page-reset .page-container h1 {
    font-size: 28px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em; }
  .page-reset .page-container h2 {
    font-size: 24px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em; }
  .page-reset .page-container .description {
    color: #191970;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;
    margin: 5px 0 60px;
    letter-spacing: 0.025em; }
  .page-reset .page-container .btn {
    margin: 0 auto; }
  .page-reset .page-container .form .ant-form-item {
    margin: 0; }
    .page-reset .page-container .form .ant-form-item:not(:last-child) {
      margin: 0 0 50px; }
    .page-reset .page-container .form .ant-form-item button {
      margin: 0 auto; }

.page-verify header {
  background-color: #171058;
  background-image: url("../svg/HeaderSmallMask.svg");
  background-size: 120%;
  background-position: center 101%;
  background-repeat: no-repeat;
  padding: 24px 0 70px; }

.page-verify .content-wrapper {
  max-width: 700px;
  margin: 0 auto;
  width: 96%;
  text-align: center; }

.page-verify .page-container {
  min-height: calc(100vh - 363px);
  display: flex;
  align-items: center; }
  .page-verify .page-container h1 {
    font-size: 28px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em; }
  .page-verify .page-container h2 {
    font-size: 24px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em; }
  .page-verify .page-container .redirect-counter {
    color: #191970;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    margin: 30px 0 0;
    letter-spacing: 0.025em; }
  .page-verify .page-container .description {
    color: #191970;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;
    margin: 5px 0 60px;
    letter-spacing: 0.025em; }
  .page-verify .page-container .btn {
    margin: 0 auto; }
  .page-verify .page-container .form .ant-form-item {
    margin: 0; }
    .page-verify .page-container .form .ant-form-item:not(:last-child) {
      margin: 0 0 50px; }
    .page-verify .page-container .form .ant-form-item button {
      margin: 0 auto; }

.page-profile header {
  background-color: #171058;
  background-image: url("../svg/HeaderSmallMask.svg");
  background-size: 120%;
  background-position: center 101%;
  background-repeat: no-repeat;
  padding: 24px 0 70px; }

.page-profile .page-container {
  min-height: calc(100vh - 363px); }

.page-profile .content-wrapper {
  height: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  max-width: 1300px; }
  .page-profile .content-wrapper.middle {
    align-items: center; }

.page-profile .left {
  max-width: 470px; }
  .page-profile .left svg {
    display: block;
    height: auto;
    max-width: 230px;
    width: 100%; }
  .page-profile .left h2 {
    font-size: 28px;
    font-weight: 400;
    color: #191970;
    margin: 20px 0 5px;
    letter-spacing: 0.01em; }
  .page-profile .left .text {
    letter-spacing: 0.02em;
    color: #191970;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400; }

.page-profile .empty-field {
  font-size: 20px;
  color: #191970;
  font-weight: 600; }

.page-profile .change-password {
  font-weight: 400;
  font-size: 12px;
  color: #191970;
  letter-spacing: 0.02em;
  cursor: pointer;
  text-decoration: underline; }

.page-profile .form {
  max-width: 640px;
  width: 100%; }
  .page-profile .form .ant-row .ant-row {
    margin: 0; }
  .page-profile .form h2 {
    font-size: 24px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em;
    text-align: center; }
  .page-profile .form .description {
    color: #191970;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;
    margin: 5px 0 60px;
    letter-spacing: 0.025em;
    text-align: center; }
  .page-profile .form .center-info {
    font-size: 13px;
    color: #7F7F80;
    letter-spacing: 0.02em; }
  .page-profile .form .text-info {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 400;
    font-size: 12px;
    color: #191970;
    letter-spacing: 0.02em; }
  .page-profile .form button {
    margin: 20px 0 0; }

@media screen and (max-width: 1680px) {
  .page-profile .content-wrapper {
    max-width: 1100px; }
  .page-profile .left {
    max-width: 380px;
    margin: 0 30px 0 0; }
  .page-profile .form {
    max-width: 540px; } }

@media screen and (max-width: 1024px) {
  .page-profile header {
    padding: 24px 0 40px; }
  .page-profile .content-wrapper {
    display: block;
    max-width: 600px; }
  .page-profile .left {
    max-width: 100%;
    margin: 0; }
  .page-profile .form {
    margin: 40px 0 0;
    max-width: 100%; } }

.page-student-inner header {
  background-color: #171058;
  background-image: url("../svg/HeaderSmallMask.svg");
  background-size: 120%;
  background-position: center 101%;
  background-repeat: no-repeat;
  padding: 24px 0 70px; }

.page-student-inner .color-1 {
  color: #3bc9ce !important; }

.page-student-inner .fw-400 {
  font-weight: 400 !important; }

.page-student-inner .fw-500 {
  font-weight: 500 !important; }

.page-student-inner .fw-600 {
  font-weight: 600 !important; }

.page-student-inner .m-t-0 {
  margin-top: 0 !important; }

.page-student-inner .fs-16 {
  font-size: 16px; }

.page-student-inner .content-wrapper {
  min-height: 320px;
  max-width: 1200px;
  margin: 0 auto;
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 45px;
  background-color: #fff;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -ms-border-radius: 20px;
  -moz-border-radius: 20px;
  box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
  -webkit-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2);
  -moz-box-shadow: 2px 2px 50px 0 rgba(5, 5, 100, 0.2); }

.page-student-inner .page-container {
  min-height: calc(100vh - 363px);
  display: flex;
  align-items: center; }
  .page-student-inner .page-container h2 {
    font-size: 22px;
    font-weight: 400;
    color: #191970;
    letter-spacing: 0.01em; }
  .page-student-inner .page-container .description {
    color: #191970;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 30px;
    letter-spacing: 0.01em; }
    .page-student-inner .page-container .description a {
      color: #191970;
      text-decoration: underline;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease; }
      .page-student-inner .page-container .description a:hover {
        color: #191970;
        opacity: .8; }
  .page-student-inner .page-container .logo {
    max-width: 350px; }
    .page-student-inner .page-container .logo img {
      display: block;
      height: auto;
      width: 100%; }
  .page-student-inner .page-container .info-box {
    max-width: 600px;
    width: calc(100% - 380px); }
  .page-student-inner .page-container div.btn {
    margin: 60px 0 0; }
    .page-student-inner .page-container div.btn-info {
      font-weight: 400;
      font-size: 12px;
      color: #191970;
      line-height: 1.8;
      letter-spacing: 0.02em;
      margin: 20px 0 0; }
  .page-student-inner .page-container div.button-wrap {
    display: flex;
    margin: 60px 0 0; }
    .page-student-inner .page-container div.button-wrap .btn {
      margin: 0 30px 0 0; }
      .page-student-inner .page-container div.button-wrap .btn:last-child {
        margin: 0; }
  .page-student-inner .page-container a.btn {
    display: block;
    margin: 60px 0 0; }
  .page-student-inner .page-container .price-box {
    margin: 50px 0 26px; }
    .page-student-inner .page-container .price-box .wrap {
      display: flex;
      align-items: center;
      line-height: 50px; }
    .page-student-inner .page-container .price-box .old-price {
      position: relative; }
      .page-student-inner .page-container .price-box .old-price:after {
        content: '';
        width: 110%;
        height: 3px;
        background-color: #EF4B6F;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5%;
        margin: auto;
        transform: rotate(-15deg); }
    .page-student-inner .page-container .price-box .new-price {
      font-size: 36px;
      font-weight: 400;
      letter-spacing: 0.02em;
      color: #EF4B6F;
      margin: 0 0 0 10px; }
    .page-student-inner .page-container .price-box .price, .page-student-inner .page-container .price-box .old-price {
      font-size: 30px;
      font-weight: 400;
      letter-spacing: 0.02em;
      color: #191970; }
    .page-student-inner .page-container .price-box .discount-info {
      color: #191970;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.025em;
      margin: 5px 0; }
      .page-student-inner .page-container .price-box .discount-info:before {
        content: '* ';
        color: #EF4B6F; }

@media screen and (max-width: 1024px) {
  .page-student-inner header {
    padding: 24px 0 40px; }
  .page-student-inner .content-wrapper {
    display: block;
    max-width: 600px; }
  .page-student-inner .page-container .logo {
    max-width: 100%;
    margin: 0 0 24px; }
    .page-student-inner .page-container .logo img {
      max-width: 280px; }
  .page-student-inner .page-container a.btn, .page-student-inner .page-container div.btn {
    margin: 40px 0 0; }
  .page-student-inner .page-container .price-box {
    margin: 30px 0 10px; }
  .page-student-inner .page-container .info-box {
    width: 100%; } }

@media screen and (max-width: 768px) {
  .page-student-inner header {
    padding: 24px 0 40px; }
  .page-student-inner .content-wrapper {
    display: block;
    max-width: 600px; }
  .page-student-inner .page-container div.button-wrap {
    display: block; }
    .page-student-inner .page-container div.button-wrap .btn {
      margin: 0 0 30px; }
      .page-student-inner .page-container div.button-wrap .btn:last-child {
        margin: 0; } }

.page-not-found {
  height: 100%;
  background-image: url("../images/main-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .page-not-found header {
    position: absolute;
    z-index: 9; }
  .page-not-found footer {
    display: none; }
  .page-not-found .page-container {
    padding: 100px 0;
    display: flex;
    align-content: center;
    box-sizing: border-box;
    height: 100vh; }
  .page-not-found .content-wrapper {
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .page-not-found .left img {
    max-width: 220px; }
  .page-not-found .right {
    max-width: 420px; }
  .page-not-found h1 {
    font-size: 48px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
    margin: 0 0 30px; }
  .page-not-found .text {
    font-size: 24px;
    font-weight: 400;
    color: #fff; }

@media screen and (max-width: 1680px) {
  .page-not-found .page-container {
    padding: 80px 0; }
  .page-not-found .content-wrapper {
    max-width: 740px; }
  .page-not-found .left img {
    max-width: 190px; }
  .page-not-found .right {
    max-width: 420px; }
  .page-not-found h1 {
    font-size: 46px;
    margin: 0 0 20px; }
  .page-not-found .text {
    font-size: 22px; } }

@media screen and (max-width: 768px) {
  .page-not-found .page-container {
    padding: 80px 0; }
  .page-not-found .content-wrapper {
    max-width: 600px; }
  .page-not-found .left img {
    max-width: 170px; }
  .page-not-found .right {
    max-width: 350px; }
  .page-not-found h1 {
    font-size: 40px;
    margin: 0 0 20px; }
  .page-not-found .text {
    font-size: 20px; } }

@media screen and (max-width: 720px) {
  .page-not-found .page-container {
    padding: 40px 0; }
  .page-not-found .content-wrapper {
    max-width: 600px;
    flex-wrap: wrap;
    align-content: center;
    text-align: center; }
  .page-not-found .left {
    width: 100%;
    margin: 0 0 40px; }
    .page-not-found .left img {
      max-width: 90px; }
  .page-not-found .right {
    max-width: 100%; }
  .page-not-found h1 {
    font-size: 20px;
    margin: 0 0 10px; }
  .page-not-found .text {
    font-size: 16px; } }
